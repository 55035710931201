<template>
  <v-app class="transparent">
    <v-container
      id="regular-tables"
      fluid
      tag="section"
    >
      <base-v-component
        heading="Branch List"/>

      <base-material-card
        icon="mdi-domain"
        title="Branches List"
        class="px-5 py-3"
      >
        <v-simple-table>
          <filter-list
            :filters="filter"
            :on-change="getBranches">
          </filter-list>
          <tbody>
          <tr v-for="branch in branches">
            <td>{{ branch.id }}</td>
            <td>{{ branch.name }}</td>
            <td>{{ branch.created_at }}</td>
            <td>{{ branch.updated_at }}</td>
            <td class="text-center" v-if="$auth.isAdmin()">
              <div class="d-inline-flex">
                <v-btn x-small fab color="green" class="mr-2" :to="{ path: '/branches/edit/'+ branch.id}">
                  <v-icon>mdi-square-edit-outline</v-icon>
                </v-btn>
                <v-btn x-small fab color="red darken-2" class="ml-2" @click="openDeleteDialog(branch)">
                  <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
              </div>
            </td>
          </tr>
          </tbody>
        </v-simple-table>
      </base-material-card>
      <div class="text-center">
        <v-pagination
          v-if="pagination.total > 10"
          v-model="query.page"
          :length="pagination.length"
          :total-visible="7"
          @input="getBranches"
          @next="getBranches"
          @previous="getBranches"/>
      </div>
      <div class="py-3"/>
      <v-btn v-if="$auth.isAdmin()" x-large fab dark fixed right bottom color="blue"  to="/branches/create">
        <v-icon large>mdi-plus</v-icon>
      </v-btn>

      <delete-dialog ref="deleteDialog" :on-delete="deleteBranch"/>
    </v-container>
  </v-app>
</template>


<script>
  import DeleteDialog from "../../components/base/DeleteDialog";
  import FilterList from "../../components/base/FilterList";

  export default {
    name: "BranchesList",
    components: {FilterList, DeleteDialog},
    created() {
      this.getUrlParams();
      this.getBranches();
    },
    watch: {
      $route() {
        this.getUrlParams();
        this.getBranches();
      }
    },
    data() {
      return {
        branches: [],
        pagination: {
          page: 1,
          length: 0,
          total: 0
        },
        query: {
          page: 1
        },
        filter: [
          {
            name: "page",
            value: 1,
            type: "pagination"
          },
          {
            name: "id",
            value: "",
            type: "text_field"
          },
          {
            name: "name",
            value: "",
            type: "text_field"
          },
          {
            name: "created_at",
            value: "",
            type: "text_field"
          },
          {
            name: "updated_at",
            value: "",
            type: "text_field"
          },
          {
            name: "options",
            value: "",
            type: "options"
          },
        ]
      }
    },
    methods: {
      getBranches() {
        this.$authApi.get(`/branches`, {
          params: this.query
        }).then(res => {
          this.branches = res.data.data.data;
          this.pagination.length = res.data.data.last_page;
          this.pagination.total = res.data.data.total;
        })
      },
      getUrlParams() {
        if (!Object.keys(this.$route.query).length) {
          this.query = {
            page: 1
          };
          this.filter = [
            {
              name: "page",
              value: 1,
              type: "pagination"
            },
            {
              name: "id",
              value: "",
              type: "text_field"
            },
            {
              name: "name",
              value: "",
              type: "text_field"
            },
            {
              name: "created_at",
              value: "",
              type: "text_field"
            },
            {
              name: "updated_at",
              value: "",
              type: "text_field"
            },
            {
              name: "options",
              value: "",
              type: "options"
            },
          ];
          this.$root.$emit('refreshParams', this.filter)
        } else {
          this.query = this.$route.query;
          this.filter.forEach(param => {
            param.value = this.query[param['name']];
          });
        }
      },
      openDeleteDialog(branch) {
        this.$refs.deleteDialog.openDialog(branch);
      },
      deleteBranch(branch) {
        this.$authApi.delete(`/branches/${branch.id}`).then(res => {
            this.getBranches();
        })
      },

    }
  }
</script>
